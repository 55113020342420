@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
/* .right-class{
  height: 303px;
  
}
.left-class{
  height: 195px;
  
} */
.nav-right{
  list-style: none;
  overflow-y: hidden;
}

.nav-right li{
  overflow: hidden;
}
.chatBody{
    padding: 0px 35px;
    overflow-y: scroll;
    height: calc(70vh - 180px);
    position: relative;
}

.chatBody::-webkit-scrollbar{
    width: 8px;
}
.chatBody::-webkit-scrollbar-track {
    background: none;
  }
.chatBody::-webkit-scrollbar-thumb {
    background: #EDEDED;
    border-radius: 8px;
  }
/* .chatBody::-webkit-scrollbar-thumb:hover {
    background: #555;
  } */

.BottomRightMsgContainer{
    display: flex;
    align-items: flex-start;
    justify-content: start;
    max-width: 70%;
}

.BottomRightMsgContainer.myMsg{
    margin-left: auto;
}

/* .BottomRightMsgDetails > p{
    padding: 0;
    margin: 0;
} */

.BottomRightMsgDetails{
    margin-left: 12px;
}

.BottomRightMsgDetails.myMsg{
    display: flex;
    flex-direction: column;
    align-items: end;
}

.BottomRightMsg{
    padding: 10px 12px;
    background-color: #EDEDED;
    border-radius: 12px;
    margin: 0;
    color: #000B23;
    font-size: 12px;
    /* max-width: 70%; */
}
.BottomRightMsg.myMsg{
    color: #FFFFFF;
    background-color: #0099A8;
}

.BottomRightTime{
    padding: 0;
    margin-top: 2px;
    font-size: 13px;
    font-weight: 500;
    color: #0E7CEB;
}
.BottomRightTime.myMsg{
    color: #F78DA7;
}

.ProfileIconChatBody{
    min-width: 48px;
    min-height: 48px;
}

.ChatBodyFormContainer{
    /* position: sticky; */
    bottom: 0vh;
    margin: 0px 35px;
    display: flex;
    justify-content: space-between;
    padding-bottom: 8px;
    border: 2px solid #EDEDED;
    border-radius: 12px;
}

.ChatBodyFormContainerInput{
    border: none;
    padding-bottom: 24px;
    outline: none;
}
.ChatBodyFormContainerInput:focus{
    border: none !important;
    outline: none !important;
}

.ChatBodyFormContainerIconsContainer{
    display: flex;
    border-top: 2px solid #EDEDED;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px;
    padding-top: 8px;
}

.ChatBodyFormContainerIcons{
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    align-items: center;
}
.chatRightHeader{
    height: 80px;
    border-bottom: 2px solid #E6EBF1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 24px 0px 24px;
}

.HeaderRight{
    display: flex;
    /* justify-content: center; */
    align-items: center;
}

.HeaderRightDetails > p{
    padding: 0;
    margin: 0;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    line-height: 22px;
}

.HeaderName{
    font-weight: 700;
    font-size: 17px;
    color: #000B23;
}

.HeaderMsg{
    font-weight: 400;
    font-size: 14px;
    color: #72849A;
    letter-spacing: 0.4px;
}

.HeaderRightDetails{
    padding-left: 8px;
}
.chatLayout{
    display: flex;
    flex-direction: row;
    border: 2px solid #E6EBF1;
    border-radius: 12px;
    height: 70vh;
    background-color: #FFFFFF;
}

/* .chatLayoutLeft{
    flex: 0.35;
    min-width: 350px;
} */

.allInBox{
    flex: 0.35 1;
    min-width: 350px;
}

.inquiries{
    border-left: 2px solid #E6EBF1;
}

.chatLayoutRight{
    flex: 1 1;
    border-left: 2px solid #E6EBF1;
}
.value{
  font-weight: 900;
font-size: 30px;
line-height: 40px;
}
p {
  font-weight: 600;
font-size: 14px;
line-height: 22px;


color: #1A3353;
}
.title{
  font-weight: 700;
  font-size: 20px!important;
  color: #1A3353; 
  margin-bottom: 1rem;
  margin-top: 0.75rem;
}
.my-card{
  justify-content: center;
}

.left{
margin-bottom: 24px;
height: 195px;
}
.right{
  margin-bottom: 27px;
  height: 303px;
}

  .icon{
    
    height: 110px;
    width: 110px;
    border-radius: 50%;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }


.apexcharts-canvas{
  overflow: hidden;
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.75);
}

.modal {
  position: fixed;
  top: 15vh;
  left: 5%;
  width: 90%;
  background-color: white;
  padding: 1rem;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 30;
  animation: slide-down 300ms ease-out forwards;
}

@media (min-width: 768px) {
  .modal {
    width: 35rem;
    left: calc(50% - 18rem);
  }
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-3rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.BasicDetails_actions__zGcCS Button {
  margin-left: 1rem;
  min-width: 125px;
}
.actions Button {
  margin-left: 1rem;
  width: 125px;
}
.actions Button {
  margin-left: 1rem;
  width: 125px;
}
.actions Button {
  margin-left: 1rem;
  width: 125px;
}
.ant-form-item-control-input-content{
  display: flex;
  justify-content: end;
}

.header{
  background: #FAFAFB;
  
  padding: 16px 1rem;

  border-radius: 8px;
}

.header p{
  margin: 0;
}
.merge-heading-card .ant-card-body{
  padding: 7px;
  display: flex;
  justify-content: center;
}

.gender {
  margin-bottom: 45px;
}
.merge-heading-card .ant-card-body{
  padding: 7px;
  display: flex;
  justify-content: center;
}

.gender {
  margin-bottom: 45px;
}
.MergeDrawer_actions__fan2R Button{
  width: 125px;
}

.MergeDrawer_merge_btn__3_5Y3{
  border: none;
  background: #0099A8;

  color: #FFFFFF;
}

.MergeDrawer_merge_btn__3_5Y3:hover,
.MergeDrawer_merge_btn__3_5Y3:active {
  background: #02a2b0;
}

.MergeDrawer_cross__11ZsZ {
  border: none;
  box-shadow: 0;
  background-color: #FFFFFF;
}

.MergeDrawer_cross__11ZsZ:hover , .MergeDrawer_cross__11ZsZ:active{
  cursor: pointer;
}


.Merge_btn__2maUS{
  border: none;
  background: #0099A8;
  color: #FFFFFF;
}

.Merge_btn__2maUS:hover,
.Merge_btn__2maUS:active {
  background: #02a2b0;
}

.AccountList_drawer__zxAi3{
  overflow-y: auto;
  border-radius: 8px 0 0 8px;
}
.actions Button {
  margin-left: 1rem;
  width: 125px;
}



* {
  font-family: 'Inter', sans-serif !important;
  
  }
  .ant-picker{
    width: 100%;
  }

  .removeAstrick .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before{
    display: none;
  }
  .dashboardChartCust .apexcharts-legend.apexcharts-align-right.apx-legend-position-top{
    right: 100px !important;
    margin-right: 0px !important;
  }
  .ScrollY {
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    overflow: auto;
    padding: 8px 24px;
    height: 300px;
  }

.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading .ant-spin {
  color: #3e79f7;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #0099A8 !important;
}

.ant-tabs-ink-bar{
  background: #0099A8 !important;
}

.tabs_centered .ant-tabs-nav-list{
  margin-left: 25.55%;
}

.ant-input-number{
  width: 100%;
}

/* SearchBox Icon */
.ant-input-search-button {
  height: 2.5rem !important;
}

/* Primary Button */

.ant-btn-primary {
  color: #fff;
  background: #0099A8 !important;
  border-color: #0099A8 !important;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}

.ant-btn-primary:focus, .ant-btn-primary:hover {
  color: #fff;
  background: #01aab9 !important;
  border-color: #01aab9 !important;
}

/* Radio  */
.ant-form-item-control-input-content{
  justify-content: start !important;
}
 .ant-tabs-tab:hover,
 ant-tabs-tab-active:hover {
  color: #01aab9 !important;
  
}

.ant-dropdown-menu-item:hover, .ant-dropdown-menu-submenu-title:hover {
  border-radius: 0.625rem;
}

/* Sorter Columns Alignments */

/* .ant-table-column-sorters{
  padding-left: 0;
} */


/* loading spin blur */
.blur {
  filter: blur(3px);
}

/* form disabled styles */
.form-disabled , .form-disabled .ant-select-selector .ant-select-selection-item{
  color: #1a3353 !important;
}


/* Icon zoom in animations */

@keyframes zoom-in {
  form{
    transform: scale(1);
  }

  to{
    transform: scale(1.4);
  }
}

.zoom-in-icon svg {
  transform-origin: center;
}

.zoom-in-icon:hover svg {
  animation: zoom-in 0.2s ease-in-out forwards;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-prev:focus {
  font-size: inherit !important;
  left: 10px !important;
  z-index: 2 !important;
  color: black  !important;
  font-size: 40px !important;
  cursor: pointer !important;
}

.ant-carousel .slick-next,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-next:focus {
  font-size: inherit !important;
  right: 10px !important;
  z-index: 2 !important;
  font-size: 40px !important;
  color: black !important;
  cursor: pointer !important;
}

iframe {
  display: none !important;
}

@page {
  size: auto;
  margin : auto auto 0 auto;
}
